/* styles.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html {
  scroll-behavior: smooth;
}
/* Additional custom styles can go here */

.bg-black {
  --tw-bg-opacity: 0.3;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  z-index: 6;
}
.dialog-main .bg-black{
  z-index: 20; 
}
.dialog-main .overflow-y-auto{
  z-index: 21; 
}
.container{max-width: 1366px;}